@use "sass:map";
@import 'bootstrap/scss/_functions.scss';
@import './variables.scss';
@import 'bootstrap/scss/bootstrap.scss';

$small: 0.875 * $font-size-base;

@import './components.scss';
@import './fullView.scss';
@import './lineView.scss';
@import './form.scss';

:root {
  --#{$prefix}box-shadow: #{$box-shadow};
  @each $size, $value in $grid-breakpoints {
    --#{$prefix}grid-breakpoint-#{$size}: #{$value};
  }
}

body {
  background-color: $light;
}

.bg-sparta {
  background-color: $spartaGeel;
}

.new-badge {
  background-color: $spartaGeel;
  color: $black;
  border-radius: 2rem;
  padding: 0 0.5rem 0.15rem;
  line-height: 1;
}

.border-black {
  border-color: $black !important;
  border-width: 2px !important;
}

.px-auto {
  padding-right: 0.75rem !important;
  padding-left: 0.75rem !important;
}

.accordion-button::after {
  transform: rotate(-90deg);
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(0deg);
}

img {
  max-width: 100%;
  height: auto;
  &[src$=".jpg"] {
    border-radius: 0.25rem;
  }
}

.object-left {
  float: left;
  margin-right: 0.5rem;
}

.object-right {
  float: right;
  margin-left: 0.5rem;
}

.ev-sparta {
  padding-right: 14px;
  background: transparent url( '../images/sparta_16x11.png') no-repeat top right;
  background-position-y: 0.25rem;
}

@media (max-width: 575px) {
  html {
    font-size: 14px;
  }
}

.max-w-menu {
  max-width: 240px;
}

.w-menu-list {
  width: 200px;
}
.hide-byline .attribute-byline {
  display: none;
}
