.content-view-full form {
  .form-attribute {
    .halfbox {
      width: 50% !important;
    }
    p {
      margin-bottom: 0.5rem;
    }
    select {
      min-width: 4rem;
    }
  }

  @media (min-width: 576px) {
    .form-attribute {
      & > label {
        flex: 0 0 8em;
        text-align: end;
        margin-right: 0.5rem;
      }
    }
  }
}
