.content-view-line {
    
  clear: both;
  break-inside: avoid;

  .attribute-header > * {
    margin: 0;
  }

  .attribute-byline {
    font-size: $small;
    color: $text-muted;
    margin-bottom: 0.25rem;
    span:not(:last-of-type) {
      &::after {
        content: ', ';
      }
    }
  }

  .attribute-image {
    float: left;
    margin-right: 0.5rem;
    max-width: 35%;
  } 

  p {
    margin-bottom: 0.25rem;
  }
}
