.content-view-full {
    
  & > .attribute-header > * {
    margin: 0;
  }

  & > .attribute-byline {
    font-size: $small;
    color: $text-muted;
    margin-bottom: 1rem;
    span:not(:last-of-type) {
      &::after {
        content: ', ';
      }
    }
  }

  & > .attribute-image {
    float: right;
    margin-left: 0.5rem;
    max-width: 35%;
  } 

  & > .attribute-short {
    font-weight: $font-weight-semibold;
  }

  @media (max-width: 575px) {
    .attribute-long h3 {
      clear: both;
    }
  }

  .event-article {
    display: flex;
    &.with-date {
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid $border-color;
    }
    .event-article-date {
      flex: 0 0 5rem;
      margin-right: 0.5rem;
      text-align: center;
      font-weight: $font-weight-semibold;
      color: $text-muted;
      font-size: $h3-font-size;
    }
  }
}
