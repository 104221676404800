
.sp-cell {
  height: 6rem;
  flex: 0 0 25%;
  overflow-x: hidden;
}

.sp-name span  {
  background-color: rgba(255, 255, 255, 0.5);
} 

@media (min-width: 576px) {
  .sp-cell {
    flex: 0 0 20%;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .sp-cell {
    flex: 0 0 14.28%;
  }
}
