footer {
  @media (min-width: 992px) {
    padding-left: 208px;
  }
}

.sponsor-logo {
  max-height: calc(10vw);
}

.page-container {
  max-width: 960px;
  min-height: 100vh;
  @media (min-width: 992px) {
    min-height: calc(100vh - 2rem);
    box-shadow: var(--bs-box-shadow);
  }
}

.expand-button {
  --bs-accordion-btn-icon-width: 0.875rem;
  --bs-accordion-btn-focus-border-color: #f00;
  --bs-accordion-btn-focus-box-shadow: 0;
  --bs-accordion-border-color: transparent;
  --bs-accordion-border-width: inherit;
  --bs-accordion-bg: transparent;
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-active-bg: transparent;
}
